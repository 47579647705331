import Link from 'next/link'

export default function Custom404() {
    return <div>
        <h1>Not found – 404!</h1>
        <div>
            <Link href="/">Go back to Home</Link>
        </div>
    </div>
}

export function generateStaticParams() {return []}